import React, { useState, useEffect, useRef } from 'react';
import './Kitchen.css';
import mapData from './map.json';

const tileSize = 16;
const gridSize = { width: 30, height: 40 };
const gridStart = { x: 5, y: 11 };
let itemCooldown = false;

const Game = () => {
  const [activeDirections, setActiveDirections] = useState<string[]>([]);
  const [lastDirection, setLastDirection] = useState<string>('down');
  const [speed, setSpeed] = useState(0);
  const speedRef = useRef(speed);
  const xRef = useRef(gridStart.x * tileSize);
  const yRef = useRef(gridStart.y * tileSize);
  const animationRef = useRef<number | null>(null);
  const [walking, setWalking] = useState(false);
  const [collisionData, setCollisionData] = useState<any>([]);
  const [floor_item, setFloorItem] = useState({ x: -300, y: -300 });
  const [pixelSize, setPixelSize] = useState(1);
  const autoMoveRef = useRef<NodeJS.Timeout | null>(null);

  useEffect(() => {
    const collisionLayer = mapData.layers.find(
      (layer) => layer.name === 'Collisions'
    );
    if (collisionLayer) {
      setCollisionData(collisionLayer.data);
    }
  }, []);

  const isCollision = (x: number, y: number) => {
    const tileSize = mapData.tilewidth;
    const mapWidth = mapData.width;
    const tileX = Math.round(x / tileSize);
    const tileY = Math.round(y / tileSize);
    const tileIndex = tileY * mapWidth + tileX;

    if (
      (tileX == floor_item.x || tileX + 1 == floor_item.x) &&
      (tileY == floor_item.y || tileY - 1 == floor_item.y) &&
      !itemCooldown
    ) {
      return { c: 'item', x: tileX, y: tileY };
    }

    return { c: collisionData[tileIndex] !== 0, x: tileX, y: tileY };
  };

  const checkCollision = (xRef: number, yRef: number) => {
    const { c: collision, x, y } = isCollision(xRef, yRef);

    if (collision == 'item' && !itemCooldown) {
      handleItemCollection(x, y);
      return true;
    } else if (collision) {
      return true;
    } else {
      return false;
    }
  };

  const directions = {
    up: 'up',
    down: 'down',
    left: 'left',
    right: 'right'
  };

  const keys: { [key: string]: string } = {
    ArrowUp: directions.up,
    ArrowLeft: directions.left,
    ArrowRight: directions.right,
    ArrowDown: directions.down
  };

  const placeCharacter = () => {
    const pixelSize = parseInt(
      getComputedStyle(document.documentElement).getPropertyValue(
        '--pixel-size'
      )
    );

    const activeDirection = activeDirections[activeDirections.length - 1];

    if (activeDirection && speed > 0) {
      setWalking(true);

      if (
        activeDirections.includes('right') &&
        !checkCollision(xRef.current + speed + 10, yRef.current + 20)
      )
        xRef.current += speed;
      if (
        activeDirections.includes('left') &&
        !checkCollision(xRef.current - speed - 10, yRef.current + 20)
      )
        xRef.current -= speed;
      if (
        activeDirections.includes('down') &&
        !checkCollision(xRef.current, yRef.current + 20 + speed)
      )
        yRef.current += speed;
      if (
        activeDirections.includes('up') &&
        !checkCollision(xRef.current, yRef.current + 20 - speed)
      )
        yRef.current -= speed;

      let direction = 'left';
      if (
        activeDirections.includes('down') &&
        activeDirections.includes('left')
      ) {
        direction = 'down-left';
      } else if (
        activeDirections.includes('down') &&
        activeDirections.includes('right')
      ) {
        direction = 'down-right';
      } else if (
        activeDirections.includes('up') &&
        activeDirections.includes('left')
      ) {
        direction = 'up-left';
      } else if (
        activeDirections.includes('up') &&
        activeDirections.includes('right')
      ) {
        direction = 'up-right';
      } else if (activeDirections.includes('up')) {
        direction = 'up';
      } else if (activeDirections.includes('left')) {
        direction = 'left';
      } else if (activeDirections.includes('right')) {
        direction = 'right';
      } else if (activeDirections.includes('down')) {
        direction = 'down';
      }
      setLastDirection(direction);
    } else {
      setWalking(false);
    }

    const leftLimit = -8;
    const rightLimit = gridSize.width * 16 - 24;
    const topLimit = -24;
    const bottomLimit = gridSize.height * 16 - 32;

    xRef.current = Math.max(Math.min(xRef.current, rightLimit), leftLimit);
    yRef.current = Math.max(Math.min(yRef.current, bottomLimit), topLimit);

    const map = document.querySelector('.map') as HTMLElement;
    const character = document.querySelector('.character') as HTMLElement;

    const cameraLeft = pixelSize * 66;
    const cameraTop = pixelSize * 42;

    if (map) {
      map.style.transform = `translate3d(${-xRef.current * pixelSize + cameraLeft}px, ${-yRef.current * pixelSize + cameraTop}px, 0)`;
    }
    if (character) {
      character.style.transform = `translate3d(${xRef.current * pixelSize}px, ${yRef.current * pixelSize}px, 0)`;
    }
  };

  const startAnimation = () => {
    const step = () => {
      if (speed > 0) {
        placeCharacter();
        animationRef.current = requestAnimationFrame(step);
      }
    };
    step();
  };

  const handleKeyDown = (e: KeyboardEvent) => {
    const direction = keys[e.key];
    if (direction) {
      setActiveDirections((prevDirections) => {
        if (!prevDirections.includes(direction)) {
          prevDirections.push(direction);
          return prevDirections;
        }
        return prevDirections;
      });
      setSpeed(0.8);
    }
  };

  const handleKeyUp = (e: KeyboardEvent) => {
    const direction = keys[e.key];
    if (direction) {
      setActiveDirections((prevDirections) => {
        prevDirections.splice(prevDirections.indexOf(direction), 1);
        if (prevDirections.length === 0) {
          setSpeed(0);
          if (animationRef.current) {
            cancelAnimationFrame(animationRef.current);
            animationRef.current = null;
          }
        }

        return prevDirections;
      });
    }
  };

  useEffect(() => {
    window.addEventListener('keydown', handleKeyDown);
    window.addEventListener('keyup', handleKeyUp);

    return () => {
      window.removeEventListener('keydown', handleKeyDown);
      window.removeEventListener('keyup', handleKeyUp);
    };
  }, []);

  useEffect(() => {
    if (speed > 0) {
      startAnimation();
    } else {
      placeCharacter();
    }
  }, [speed]);

  const handleDpadPress = (direction: string, click = false) => {
    if (direction) {
      setActiveDirections((prev) => {
        return [direction];
      });
      setSpeed(0.8);
    }
  };

  const handleTouchEnd = () => {
    setActiveDirections((prev) => {
      setSpeed(0);
      if (animationRef.current) {
        cancelAnimationFrame(animationRef.current);
        animationRef.current = null;
      }
      return [];
    });
  };

  useEffect(() => {
    const rootStyle = getComputedStyle(document.documentElement);
    const pixelSizeValue = parseFloat(
      rootStyle.getPropertyValue('--pixel-size')
    );
    setPixelSize(pixelSizeValue);
    if (collisionData.length > 0) {
      generateRandomPosition();
    }
  }, [collisionData]);

  const handleResize = () => {
    placeCharacter();
    const rootStyle = getComputedStyle(document.documentElement);
    const pixelSizeValue = parseFloat(
      rootStyle.getPropertyValue('--pixel-size')
    );
    setPixelSize(pixelSizeValue);
  };
  useEffect(() => {
    window.addEventListener('resize', handleResize);

    return () => {
      window.removeEventListener('resize', handleResize);
    };
  }, [floor_item]);

  const floorItemStyle: React.CSSProperties = {
    position: 'absolute',
    left: `${floor_item.x * tileSize * pixelSize}px`,
    top: `${floor_item.y * tileSize * pixelSize}px`,
    width: `${tileSize * pixelSize}px`,
    height: `${tileSize * pixelSize}px`,
    backgroundColor: 'red'
  };

  const handleItemCollection = (x: number, y: number) => {
    // Ajout d'un cooldown pour éviter de ramasser plusieurs fois le même objet
    itemCooldown = true;
    setFloorItem((prev) => ({ ...prev, x: -300, y: -300 }));
    get_floor_item(x, y);

    // Remise à zéro du cooldown après 500 ms
    setTimeout(() => {
      itemCooldown = false;
    }, 2000); // 500 ms de délai avant de permettre la re-détection
  };

  async function get_floor_item(x: number, y: number) {
    //more logic here to come
    // console.log('item collected', floor_item.x, floor_item.y);
    generateRandomPosition();
  }

  const generateRandomPosition = () => {
    let x, y;
    do {
      x = Math.floor(Math.random() * gridSize.width - 1) + 1;
      y = Math.floor(Math.random() * 10) + 1;
    } while (isCollision(x * tileSize, y * tileSize).c === true);
    setFloorItem((prev) => ({ ...prev, x, y }));
  };

  const disableScroll = () => {
    document.body.style.overflow = 'hidden';
  };

  // Fonction pour réactiver le scroll
  const enableScroll = () => {
    document.body.style.overflow = 'auto';
  };

  // Fonction pour générer une direction aléatoire
  const getRandomDirection = () => {
    const directions = ['up', 'down', 'left', 'right'];
    return directions[Math.floor(Math.random() * directions.length)];
  };
  useEffect(() => {
    speedRef.current = speed;
  }, [speed]);
  // Fonction pour faire avancer le chat aléatoirement
  const startRandomMovement = () => {
    autoMoveRef.current = setInterval(
      () => {
        if (speedRef.current === 0) {
          const randomDirection = getRandomDirection();
          setActiveDirections([randomDirection]);
          setSpeed(Math.random() * 1);
        } else {
          setActiveDirections([]);
          setSpeed(0);
        }
      },
      Math.floor(Math.random() * (3000 - 500 + 1)) + 500
    ); // Change la direction toutes les 2 secondes
  };

  // Arrêter le mouvement automatique
  const stopRandomMovement = () => {
    if (autoMoveRef.current) {
      clearInterval(autoMoveRef.current);
    }
  };

  // Démarrer le mouvement aléatoire lors du montage
  useEffect(() => {
    startRandomMovement();
    return () => {
      stopRandomMovement();
    };
  }, []);

  return (
    <div>
      {' '}
      {floor_item.x};{floor_item.y}
      <div className='page' onFocus={disableScroll} onBlur={enableScroll}>
        {''}
        <div className='frame'>
          <div className='corner_topleft'></div>
          <div className='corner_topright'></div>
          <div className='corner_bottomleft'></div>
          <div className='corner_bottomright'></div>

          <div className='camera'>
            <div className='map pixel-art'>
              <div
                className='character'
                data-facing={lastDirection}
                data-walking={walking ? 'true' : 'false'}
              >
                {' '}
                <div className='shadow pixel-art'></div>
                <div className='character_spritesheet pixel-art'></div>
              </div>
              <div className='floor_item' style={floorItemStyle}></div>
              <div className='map-overlay'></div>
            </div>

            <div className='dpad'>
              <div className='DemoDirectionUI flex-center'>
                <button
                  className='dpad-button dpad-left'
                  onMouseDown={() => handleDpadPress(directions.left, true)}
                  onTouchStart={() => handleDpadPress(directions.left, true)}
                  onMouseUp={handleTouchEnd}
                  onTouchEnd={handleTouchEnd}
                >
                  <svg
                    xmlns='http://www.w3.org/2000/svg'
                    viewBox='0 -0.5 13 13'
                    shapeRendering='crispEdges'
                  >
                    <path
                      className='Arrow_outline-top'
                      stroke='#5f5f5f'
                      d='M1 0h11M0 1h1M12 1h1M0 2h1M12 2h1M0 3h1M12 3h1M0 4h1M12 4h1M0 5h1M12 5h1M0 6h1M12 6h1M0 7h1M12 7h1M0 8h1M12 8h1'
                    />
                    <path
                      className='Arrow_surface'
                      stroke='#f5f5f5'
                      d='M1 1h11M1 2h11M1 3h5M7 3h5M1 4h4M7 4h5M1 5h3M7 5h5M1 6h4M7 6h5M1 7h5M7 7h5M1 8h11'
                    />
                    <path
                      className='Arrow_arrow-inset'
                      stroke='#434343'
                      d='M6 3h1M5 4h1M4 5h1'
                    />
                    <path
                      className='Arrow_arrow-body'
                      stroke='#5f5f5f'
                      d='M6 4h1M5 5h2M5 6h2M6 7h1'
                    />
                    <path
                      className='Arrow_outline-bottom'
                      stroke='#434343'
                      d='M0 9h1M12 9h1M0 10h1M12 10h1M0 11h1M12 11h1M1 12h11'
                    />
                    <path className='Arrow_edge' stroke='#ffffff' d='M1 9h11' />
                    <path
                      className='Arrow_front'
                      stroke='#cccccc'
                      d='M1 10h11M1 11h11'
                    />
                  </svg>
                </button>
                <button
                  className='dpad-button dpad-up'
                  onMouseDown={() => handleDpadPress(directions.up, true)}
                  onTouchStart={() => handleDpadPress(directions.up, true)}
                  onMouseUp={handleTouchEnd}
                  onTouchEnd={handleTouchEnd}
                >
                  <svg
                    xmlns='http://www.w3.org/2000/svg'
                    viewBox='0 -0.5 13 13'
                    shapeRendering='crispEdges'
                  >
                    <path
                      className='Arrow_outline-top'
                      stroke='#5f5f5f'
                      d='M1 0h11M0 1h1M12 1h1M0 2h1M12 2h1M0 3h1M12 3h1M0 4h1M12 4h1M0 5h1M12 5h1M0 6h1M12 6h1M0 7h1M12 7h1M0 8h1M12 8h1'
                    />
                    <path
                      className='Arrow_surface'
                      stroke='#f5f5f5'
                      d='M1 1h11M1 2h11M1 3h11M1 4h5M7 4h5M1 5h4M8 5h4M1 6h3M9 6h3M1 7h11M1 8h11'
                    />
                    <path
                      className='Arrow_arrow-inset'
                      stroke='#434343'
                      d='M6 4h1M5 5h1M7 5h1'
                    />
                    <path
                      className='Arrow_arrow-body'
                      stroke='#5f5f5f'
                      d='M6 5h1M4 6h5'
                    />
                    <path
                      className='Arrow_outline-bottom'
                      stroke='#434343'
                      d='M0 9h1M12 9h1M0 10h1M12 10h1M0 11h1M12 11h1M1 12h11'
                    />
                    <path className='Arrow_edge' stroke='#ffffff' d='M1 9h11' />
                    <path
                      className='Arrow_front'
                      stroke='#cccccc'
                      d='M1 10h11M1 11h11'
                    />
                  </svg>
                </button>
                <button
                  className='dpad-button dpad-down'
                  onMouseDown={() => handleDpadPress(directions.down, true)}
                  onTouchStart={() => handleDpadPress(directions.down, true)}
                  onMouseUp={handleTouchEnd}
                  onTouchEnd={handleTouchEnd}
                >
                  <svg
                    xmlns='http://www.w3.org/2000/svg'
                    viewBox='0 -0.5 13 13'
                    shapeRendering='crispEdges'
                  >
                    <path
                      className='Arrow_outline-top'
                      stroke='#5f5f5f'
                      d='M1 0h11M0 1h1M12 1h1M0 2h1M12 2h1M0 3h1M12 3h1M0 4h1M12 4h1M0 5h1M12 5h1M0 6h1M12 6h1M0 7h1M12 7h1M0 8h1M12 8h1'
                    />
                    <path
                      className='Arrow_surface'
                      stroke='#f5f5f5'
                      d='M1 1h11M1 2h11M1 3h11M1 4h3M9 4h3M1 5h4M8 5h4M1 6h5M7 6h5M1 7h11M1 8h11'
                    />
                    <path
                      className='Arrow_arrow-inset'
                      stroke='#434343'
                      d='M4 4h5'
                    />
                    <path
                      className='Arrow_arrow-body'
                      stroke='#5f5f5f'
                      d='M5 5h3M6 6h1'
                    />
                    <path
                      className='Arrow_outline-bottom'
                      stroke='#434343'
                      d='M0 9h1M12 9h1M0 10h1M12 10h1M0 11h1M12 11h1M1 12h11'
                    />
                    <path className='Arrow_edge' stroke='#ffffff' d='M1 9h11' />
                    <path
                      className='Arrow_front'
                      stroke='#cccccc'
                      d='M1 10h11M1 11h11'
                    />
                  </svg>
                </button>
                <button
                  className='dpad-button dpad-right'
                  onMouseDown={() => handleDpadPress(directions.right, true)}
                  onTouchStart={() => handleDpadPress(directions.right, true)}
                  onMouseUp={handleTouchEnd}
                  onTouchEnd={handleTouchEnd}
                >
                  <svg
                    xmlns='http://www.w3.org/2000/svg'
                    viewBox='0 -0.5 13 13'
                    shapeRendering='crispEdges'
                  >
                    <path
                      className='Arrow_outline-top'
                      stroke='#5f5f5f'
                      d='M1 0h11M0 1h1M12 1h1M0 2h1M12 2h1M0 3h1M12 3h1M0 4h1M12 4h1M0 5h1M12 5h1M0 6h1M12 6h1M0 7h1M12 7h1M0 8h1M12 8h1'
                    />
                    <path
                      className='Arrow_surface'
                      stroke='#f5f5f5'
                      d='M1 1h11M1 2h11M1 3h5M7 3h5M1 4h5M8 4h4M1 5h5M9 5h3M1 6h5M8 6h4M1 7h5M7 7h5M1 8h11'
                    />
                    <path
                      className='Arrow_arrow-inset'
                      stroke='#434343'
                      d='M6 3h1M7 4h1M8 5h1'
                    />
                    <path
                      className='Arrow_arrow-body'
                      stroke='#5f5f5f'
                      d='M6 4h1M6 5h2M6 6h2M6 7h1'
                    />
                    <path
                      className='Arrow_outline-bottom'
                      stroke='#434343'
                      d='M0 9h1M12 9h1M0 10h1M12 10h1M0 11h1M12 11h1M1 12h11'
                    />
                    <path className='Arrow_edge' stroke='#ffffff' d='M1 9h11' />
                    <path
                      className='Arrow_front'
                      stroke='#cccccc'
                      d='M1 10h11M1 11h11'
                    />
                  </svg>
                </button>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Game;
