import React from 'react';
import type { WalletConnectLoginButtonPropsType } from '@multiversx/sdk-dapp/UI';
import { WalletConnectLoginButton } from 'components/sdkDappComponents';
import { nativeAuth } from 'config';
import { RouteNamesEnum } from 'localConstants';
import { useNavigate } from 'react-router-dom';
import { AuthRedirectWrapper } from 'wrappers';
// import { WebWalletLoginWrapper, XaliasLoginWrapper } from './components';

type CommonPropsType = WalletConnectLoginButtonPropsType;
// | OperaWalletLoginButtonPropsType
// | ExtensionLoginButtonPropsType
// | WebWalletLoginButtonPropsType
// | LedgerLoginButtonPropsType
// choose how you want to configure connecting to the web wallet
// const USE_WEB_WALLET_CROSS_WINDOW = true;

// const WebWalletLoginButton = USE_WEB_WALLET_CROSS_WINDOW
//   ? CrossWindowLoginButton
//   : WebWalletUrlLoginButton;

export const Unlock = () => {
  const navigate = useNavigate();
  const commonProps: CommonPropsType = {
    callbackRoute: RouteNamesEnum.wallet,
    nativeAuth,
    onLoginRedirect: () => {
      navigate(RouteNamesEnum.wallet);
    }
  };

  const getRandomUrl = () => {
    const urls = [
      'https://xport.al/referral/nggqcqggkm',
      'https://xport.al/referral/dzoz3ley12',
      'https://xport.al/referral/sg8vd5ou3j'
    ];
    // Return a random URL from the array
    return urls[Math.floor(Math.random() * urls.length)];
  };

  return (
    <AuthRedirectWrapper requireAuth={false}>
      <div className='flex justify-center items-center'>
        <div
          className='flex flex-col p-6 items-center justify-center gap-4 rounded-xl bg-[#f6f8fa]'
          data-testid='unlockPage'
        >
          <div className='flex flex-col items-center gap-1'>
            <h2 className='text-2xl'>Login</h2>

            <p className='text-center text-gray-400'>Choose a login method</p>
            <div>
              <a
                target='_blank'
                rel='noopener noreferrer'
                className='flex items-center text-sm hover:underline mx-5'
                href={getRandomUrl()}
              >
                No Wallet ? Install xPortal App{' '}
              </a>
            </div>
          </div>

          <div className='flex flex-col md:flex-row'>
            <WalletConnectLoginButton
              loginButtonText='xPortal App'
              callbackRoute={RouteNamesEnum.wallet}
              {...commonProps}
            />
          </div>
        </div>
      </div>
    </AuthRedirectWrapper>
  );
};
