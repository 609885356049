import GetUserEmptyLitterButton from 'components/helpers/postUserEmptyLitter';
import GetUserFeedButton from 'components/helpers/postUserFeed';
import GetUserPetButton from 'components/helpers/postUserPet';
import React from 'react';
import GetEnterInterest from 'components/helpers/useGetEnterInterst';
import GetUserPlayButton from 'components/helpers/postUserPlay';
import GetUserPetDbButton from 'components/helpers/postUserPetDb';
import GetUserFeedDbButton from 'components/helpers/postUserFeedDb';
import GetUserPlayDbButton from 'components/helpers/postUserPlayDb';
import GetUserEmptyLitterDbButton from 'components/helpers/postUserEmptyLitterDb';
import LobbyMenu from 'components/LobbyMenu';
const FeedPage = () => {
  return (
    <div>
      <div className='grid-container'>
        <GetUserFeedDbButton />
      </div>
      <LobbyMenu />
    </div>
  );
};

export default FeedPage;
