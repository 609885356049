import { AbiRegistry, SmartContract, Address } from '@multiversx/sdk-core/out';
import json from 'cacat-actions.abi.json';
import jsonstake from 'staking-contract.abi.json';
import { stakingContract } from 'config/index';
import { shards } from 'config/index';
// const abiRegistry = AbiRegistry.create(json);
// const abi = new SmartContractAbi(abiRegistry);
const abi = AbiRegistry.create(json);
const abistake = AbiRegistry.create(jsonstake);

export const stakingSC = new SmartContract({
  address: new Address(stakingContract),
  abi: abistake
});

export function getScByShard(shardIndex: number) {
  return new SmartContract({
    address: new Address(shards[shardIndex]),
    abi
  });
}
