import React, { useContext } from 'react';
import axios from 'axios';
import { UserContext } from 'components/hooks/useUser';
import { network } from 'config';
import gas_logo from 'img/gas_logo.png';
import reloading from 'img/reloading.png';
import notreloading from 'img/notreloading.png';
import TapGame from 'components/TapGame';

const GetClaimGasButton = () => {
  const context = useContext(UserContext);
  if (!context) {
    throw new Error('UserContextProvider');
  }
  const { user, setUser, refresh, setRefresh } = context;

  const postUserClaimGas = async () => {
    if (window.Telegram.WebApp.initData) {
      const initData = window.Telegram.WebApp.initData;
      try {
        const response = await axios.post(network.cacatApi + '/user/claimgas', {
          initData
        });

        const data = response.data;
        if (data.message) {
          alert(data.message);
        }
        if (data.success) {
          setUser((prevUser) => ({
            ...prevUser,
            gas: 0
          }));
          setRefresh((r) => ({
            ...r,
            user_egld: true,
            user_vm: true,
            refreshing: true,
            txHash: data.txHash
          }));
        } else {
          alert('Claim gas failed');
          await console.log(`User claim failed: ${JSON.stringify(data)}`);
        }
      } catch (error) {
        alert('Error during claim gas');
        await console.log(`Error during claim gas: ${error}`);
      }
    }
  };

  return (
    <>
      <div className='item'>
        {user.gas > 0 ? user.gas : 0} %
        <img className='inline-incon' src={gas_logo} alt='gas-logo' /> gas
        <TapGame
          imageReady={user.gas < 100 ? notreloading : reloading}
          imageExec={reloading}
          imageLow={reloading}
          gasCost={0}
          userGas={user.egld_amount}
          userCacat={user.cacat_amount}
          tokenCost={0}
          requiredClicks={user.gas}
          onAction={postUserClaimGas}
          disabled={user.gas < 100}
        />
        {/* <>
        -
        {formatAmount({
          input: (BigInt(minGas.claimGas) * BigInt(100000)).toString(),
          decimals: 18,
          digits: 2,
          showLastNonZeroDecimal: false
        })}
        <img className='inline-incon' src={gas_logo} alt='gas-logo' />
      </> */}
      </div>
    </>
  );
};

export default GetClaimGasButton;
