import React, { useContext, useEffect, useState } from 'react';
import {
  BrowserRouter as Router,
  Route,
  Routes,
  Link,
  useLocation
} from 'react-router-dom';
import UpgradePage from './pages/UpgradePage';
import ActionsPage from './pages/ActionsPage';
import EnergyPage from './pages/EnergyPage';
import AboutPage from './pages/AboutPage';
import NotFoundPage from './pages/NotFoundPage';
import InterestPage from './pages/InterestPage';
import './App.css';
import { UserContext } from 'components/hooks/useUser';
import GetRegisterButton from './components/helpers/postUserRegister';
import { useGetUserInfo } from './components/helpers/useGetUserInfo.js';
import useGetAccountInfo from './components/userWallet';
import cacat_logo from 'img/cacat_logo.png';
import gas_logo from 'img/gas_logo.png';
import energy_logo from 'img/energy_logo.png';
import { formatAmount } from '@multiversx/sdk-dapp/utils/operations';
import BottomMenu from 'components/BottomMenu';
import './components/ImageButton.css';
import WithdrawPage from 'pages/WithdrawPage';
import { Unlock } from 'pages/Unlock';
import { DappProvider, AxiosInterceptorContext } from './components';
import {
  apiTimeout,
  walletConnectV2ProjectId,
  environment,
  sampleAuthenticatedDomains
} from 'config';
import Game from 'pages/Kitchen/Kitchen';
import LobyPage from 'pages/Lobby';
import PetPage from 'pages/PetPage';
import LitterPage from 'pages/LitterPage';
import PlayPage from 'pages/PlayPage';
import FeedPage from 'pages/FeedPage';
import BurgerMenu from 'components/BurgerMenu';
import AlertGasLow from 'components/AlertGasLow';
import { ContextProvider } from 'context';
import { BatchTransactionsContextProvider } from 'wrappers';

// import { Layout } from 'components';

import {
  TransactionsToastList,
  SignTransactionsModals,
  NotificationModal
} from '@multiversx/sdk-dapp/UI';
import GasDepositPage from 'pages/GasDepositPage';
import MissionsPage from 'pages/MissionsPage';

const AppContent = () => {
  const context = useContext(UserContext);
  if (!context) {
    throw new Error('App must be used within a UserContextProvider');
  }
  const { user, setUser, refresh, setRefresh } = context;

  //load from api
  useGetAccountInfo(user?.address, user?.shard);
  // useGetUserInfo(user?.address, user?.shard);

  const [windowSize, setWindowSize] = useState({
    width: window.innerWidth,
    height: window.innerHeight
  });

  const handleAction = () => {
    console.log('Action executed!');
  };

  return (
    <ContextProvider>
      <DappProvider
        environment={environment}
        customNetworkConfig={{
          name: 'customConfig',
          apiTimeout,
          walletConnectV2ProjectId
        }}
        dappConfig={{
          shouldUseWebViewProvider: true,
          logoutRoute: '/home'
        }}
        customComponents={{
          transactionTracker: {
            // uncomment this to use the custom transaction tracker
            // component: TransactionsTracker,
            props: {
              onSuccess: (sessionId: string) => {
                console.log(`Session ${sessionId} successfully completed`);
              },
              onFail: (sessionId: string, errorMessage: string) => {
                console.log(
                  `Session ${sessionId} failed. ${errorMessage ?? ''}`
                );
              }
            }
          }
        }}
      >
        <AxiosInterceptorContext.Listener>
          <TransactionsToastList />
          <NotificationModal />
          <SignTransactionsModals />
          <div className='App'>
            <div className='app-container' style={{ paddingBottom: '20px' }}>
              <div className='homeHeader'>
                <div className='appTitle'>Cacat</div>
                <div className='logoHeader'>
                  <img src={cacat_logo} />
                </div>
                <div className='App-header'>
                  <p>
                    <span className='text-with-icon'>
                      {' '}
                      Energy:{' '}
                      {formatAmount({
                        input: BigInt(user.energy).toString(),
                        decimals: 0,
                        digits: 0,
                        showLastNonZeroDecimal: false,
                        addCommas: true
                      })}
                      <img
                        className='inline-incon'
                        src={energy_logo}
                        alt='cacat-logo'
                      />
                    </span>{' '}
                    <span className='text-with-icon'>
                      {' '}
                      Gas:{' '}
                      {formatAmount({
                        input: (
                          BigInt(user.egld_amount) * BigInt(100000)
                        ).toString(),
                        decimals: 18,
                        digits: 0,
                        showLastNonZeroDecimal: false,
                        addCommas: true
                      })}
                      <img
                        className='inline-incon'
                        src={gas_logo}
                        alt='cacat-logo'
                      />
                    </span>
                    <span className='text-with-icon'>
                      {' '}
                      Cacat:{' '}
                      {formatAmount({
                        input: user.cacat_amount.toFixed(),
                        decimals: 18,
                        digits: 2,
                        showLastNonZeroDecimal: false,
                        addCommas: true
                      })}
                      <img
                        className='inline-incon'
                        src={cacat_logo}
                        alt='cacat-logo'
                      />
                    </span>
                  </p>
                  {/* {refresh.txHash} */}
                </div>
              </div>
              <div className='appBody'>
                {' '}
                <AlertGasLow />
                {user.check ? (
                  <>
                    <BurgerMenu />
                    {user.id ? (
                      <>
                        {/* {user.egld_amount < BigInt(1) ? (
                        <>
                          <Routes>
                            <Route path='*' element={<EnergyPage />} />
                          </Routes>
                        </>
                      ) : ( */}
                        <>
                          <Routes>
                            <Route path='/actionpet' element={<PetPage />} />
                            <Route path='/actionplay' element={<PlayPage />} />
                            <Route path='/actionfeed' element={<FeedPage />} />
                            <Route
                              path='/actionlitter'
                              element={<LitterPage />}
                            />
                            {/* <Route path='/pet' element={<ActionsPage />} /> */}
                            <Route path='/upgrade' element={<UpgradePage />} />
                            <Route path='/wallet' element={<EnergyPage />} />
                            <Route path='/about' element={<AboutPage />} />
                            <Route
                              path='/interest'
                              element={<InterestPage />}
                            />{' '}
                            <Route
                              path='/missions'
                              element={<MissionsPage />}
                            />
                            <Route
                              path='/withdraw'
                              element={<WithdrawPage />}
                            />
                            <Route path='/unlock' element={<Unlock />} />
                            <Route path='/explore' element={<LobyPage />} />
                            <Route
                              path='/deposit'
                              element={<GasDepositPage />}
                            />
                            <Route path='*' element={<PetPage />} />
                            {/* <Route path='*' element={<ActionsPage />} /> */}
                          </Routes>
                          <div style={{ paddingBottom: '45px' }}></div>
                          {/* <BottomMenu /> */}
                        </>
                        {/* )} */}
                      </>
                    ) : (
                      <>
                        <div className='item'>
                          <GetRegisterButton />
                        </div>
                      </>
                    )}
                  </>
                ) : (
                  <Routes>
                    <Route path='/kitchen' element={<Game />} />
                    <Route path='/lobby' element={<LobyPage />} />
                    <Route path='/actionpet' element={<PetPage />} />
                    <Route path='/actionplay' element={<PlayPage />} />
                    <Route path='/actionfeed' element={<FeedPage />} />
                    <Route path='/actionlitter' element={<LitterPage />} />
                    <Route path='/withdraw' element={<WithdrawPage />} />
                    <Route path='/deposit' element={<GasDepositPage />} />
                    <Route path='/unlock' element={<Unlock />} />
                    <Route path='/missions' element={<MissionsPage />} />
                    <Route path='*' element={<NotFoundPage />} />
                  </Routes>
                )}
              </div>
            </div>
          </div>
        </AxiosInterceptorContext.Listener>
      </DappProvider>{' '}
    </ContextProvider>
  );
};

const App = () => {
  return (
    <AxiosInterceptorContext.Provider>
      <AxiosInterceptorContext.Interceptor
        authenticatedDomains={sampleAuthenticatedDomains}
      >
        <Router>
          <BatchTransactionsContextProvider>
            <AppContent />
          </BatchTransactionsContextProvider>
        </Router>
      </AxiosInterceptorContext.Interceptor>
    </AxiosInterceptorContext.Provider>
  );
};

export default App;
