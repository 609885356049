import React, { useEffect, useState } from 'react';
import './FlyToTarget.css';
import cacat_logo from 'img/cacat_logo.png';

interface PiecePosition {
  startX: number;
  startY: number;
  noise?: number;
}

interface FlyToTargetProps {
  pieces: PiecePosition[]; // Tableau contenant les positions de départ de chaque pièce
  targetId: string; // ID de la div cible
  onComplete?: () => void; // Callback quand l'animation est terminée
  hidden?: boolean;
}

const FlyToTarget = ({
  pieces,
  targetId,
  onComplete,
  hidden
}: FlyToTargetProps) => {
  const [targetPosition, setTargetPosition] = useState<{
    x: number;
    y: number;
  } | null>(null);

  useEffect(() => {
    // Obtenir la position de la div cible
    const targetElement = document.getElementById(targetId);
    if (targetElement) {
      const rect = targetElement.getBoundingClientRect();
      setTargetPosition({
        x: rect.left + rect.width / 2,
        y: rect.top + rect.height / 2
      });

      // Lancer une animation de disparition après 1 seconde (temps d'animation)
      const timer = setTimeout(() => {
        if (onComplete) {
          onComplete(); // Appelle une fonction de rappel si nécessaire
        }
      }, 1000);

      return () => clearTimeout(timer);
    }
  }, [targetId, onComplete, hidden, pieces]);

  if (!targetPosition) return null;

  return (
    <>
      {pieces.map((piece, index) => (
        <img
          key={index}
          src={cacat_logo}
          className='animated-image'
          style={{
            left: `${piece.startX}px`,
            top: `${piece.startY}px`,
            transform: `translate(${targetPosition.x - piece.startX}px, ${
              targetPosition.y - piece.startY
            }px)`,
            animationDelay: `${index * 0.2}s` // Délai pour chaque pièce (facultatif)
          }}
          alt={`piece-${index}`}
        />
      ))}
    </>
  );
};

export default FlyToTarget;
