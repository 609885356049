import React, { useState } from 'react';
import { useContext } from 'react';
import { UserContext } from 'components/hooks/useUser';
import axios from 'axios';
import { c_actions, network } from 'config';
import playReady from 'img/playReady.png';
import petExec from 'img/petExec.png';
import petLow from 'img/petLow.png';
import TapGame from 'components/TapGame';
import RewardCalcul, { calculateTotalReward } from './RewardCalcul';
import RewardCalcul2 from './RewardCalcul';
import FlyToTarget from 'components/FlyToTarget';

const GetUserPlayDbButton = () => {
  const [startPosition, setStartPosition] = useState({ x: 0, y: 0 });

  const action_id = 2;
  const action_type = 'action';
  const currentDate = new Date();
  const context = useContext(UserContext);
  if (!context) {
    throw new Error('UserContextProvider');
  }
  const { user, setUser, refresh, setRefresh } = context;

  const postUserPlay = async () => {
    if (window.Telegram.WebApp.initData) {
      const initData = window.Telegram.WebApp.initData;
      try {
        const response = await axios.post(
          network.cacatApi + '/user/actionoff',
          {
            initData,
            action_id: action_id,
            action_lvl: user.play_lvl
          }
        );

        const data = response.data;
        if (data.message) {
          alert(data.message);
        }
        if (Array.isArray(data?.actions) || Array.isArray(data?.litter)) {
          const current = calculateTotalReward(
            data.litter ? data.litter : user.litter,
            user.litter_lvl
          );
          const percent = (Number(current) / Number(user.litter_limit)) * 100;

          setUser((prevUser) => ({
            ...prevUser,
            actions: data.actions,
            litter: data.litter ? data.litter : user.litter,
            litter_calcul: current,
            litter_percent: percent,
            energy:
              data.user.energy !== undefined && data.user.energy !== null
                ? data.user.energy
                : user.energy,
            isoDate: data?.currentTime
              ? new Date(data?.currentTime)
              : currentDate
          }));
          handleAnimation();
        }

        if (data.success) {
          // setRefresh((r) => ({
          //   ...r,
          //   user_db: true,
          //   refreshing: true
          // }));
        } else {
          alert('Pet failed');
          await console.log(`User pet failed: ${JSON.stringify(data)}`);
        }
      } catch (error) {
        alert('Pet failed ' + error);
        await console.log(`Error during pet: ${error}`);
      }
    }
  };

  const matchingAction: any = user?.actions?.filter(
    (action: any) => action.action_id === action_id && action.type === 'action'
  );
  const handleAnimation = () => {
    const tapGameDiv = document.getElementById('tap-game');
    let tapGameDivPosition = { x: 0, y: 0 };
    if (tapGameDiv) {
      const rect = tapGameDiv.getBoundingClientRect();
      tapGameDivPosition = { x: rect.left, y: rect.top };
    }
    setStartPosition({ x: tapGameDivPosition.x, y: tapGameDivPosition.y });
  };
  const handleAnimationComplete = () => {
    setStartPosition({ x: 0, y: 0 }); // Reset après animation
  };

  return (
    <>
      {user.play_lvl > BigInt(0) && (
        <div className='item'>
          {c_actions[action_id].name}{' '}
          {/* <RewardCalcul
            action_id={action_id}
            action_lvl={user.play_lvl}
            litter_lvl={0}
          /> */}
          <RewardCalcul2
            reward={c_actions[action_id].reward}
            action_lvl={user.play_lvl}
          />
          <TapGame
            imageReady={playReady}
            imageExec={petExec}
            imageLow={petLow}
            gasCost={c_actions[action_id][action_type].gas_cost}
            eneregyCost={c_actions[action_id][action_type].energy_cost}
            userGas={user.egld_amount}
            userCacat={user.cacat_amount}
            tokenCost={0}
            requiredClicks={10}
            onAction={postUserPlay}
            disabled={BigInt(user.litter_calcul) >= BigInt(user.litter_limit)}
            matchingAction={matchingAction}
          />{' '}
          {startPosition && (
            <FlyToTarget
              pieces={[{ startX: startPosition.x, startY: startPosition.y }]}
              // startX={startPosition.x}
              // startY={startPosition.y}
              targetId='target-litter'
              onComplete={handleAnimationComplete}
            />
          )}
        </div>
      )}
    </>
  );
};

export default GetUserPlayDbButton;
