import React from 'react';
import { useContext } from 'react';
import { UserContext } from 'components/hooks/useUser';
import axios from 'axios';
import { network } from 'config';
import robber from 'img/robber.png';
import { formatAmount } from '@multiversx/sdk-dapp/utils/operations';
import { minGas } from 'config';
import cacat_logo from 'img/cacat_logo.png';
import energy_logo from 'img/energy_logo.png';
import e_compass_logo from 'img/e_compass_logo.png';
import TapGame from 'components/TapGame';
import useGetScBalance from './useGetScBalance';
import BigNumber from 'bignumber.js';
import gas_logo from 'img/gas_logo.png';

const GetUserPostVoteCacat = () => {
  // https://explorer.multiversx.com/transactions/2ec3a677b634ca763acbecddc79cf056cf2bf66015b9f589b4e5ad632a692307#smart
  // 0.00084108

  const context = useContext(UserContext);
  if (!context) {
    throw new Error('UserContextProvider');
  }
  const { user, setUser, refresh, setRefresh } = context;

  useGetScBalance(user.shard);
  const min_hold = BigInt(10 ** 16);

  const postUserPetUpgrade = async () => {
    if (window.Telegram.WebApp.initData) {
      const initData = window.Telegram.WebApp.initData;
      try {
        const response = await axios.post(
          network.cacatApi + '/user/votecacat',
          {
            initData
          }
        );

        const data = response.data;
        if (data.message) {
          alert(data.message);
        }
        if (data.success) {
          setUser((prevUser) => ({
            ...prevUser,
            bank_balance: new BigNumber(0),
            energy: data.user.energy
          }));
          setRefresh((r) => ({
            ...r,
            user_egld: true,
            user_cacat: true,
            user_vm: true,
            refreshing: true,
            txHash: data.txHash
          }));
        } else {
          alert('Withdraw failed');
          await console.log(`User withdraw failed: ${JSON.stringify(data)}`);
        }
      } catch (error) {
        alert('Withdraw failed');
        await console.log(`Error during withdraw: ${error}`);
      }
    }
  };

  return (
    <div className='item'>
      Upvote cacat{' '}
      <>
        {formatAmount({
          input: '60',
          decimals: 0,
          digits: 0,
          showLastNonZeroDecimal: false
        })}
        <img className='inline-incon' src={energy_logo} alt='cacat-logo' />
      </>
      {}
      <TapGame
        imageReady={e_compass_logo}
        imageExec={e_compass_logo}
        imageLow={e_compass_logo}
        gasCost={minGas.vote_cacat}
        userGas={user.egld_amount}
        userCacat={user.cacat_amount}
        tokenCost={0}
        requiredClicks={10}
        onAction={postUserPetUpgrade}
        disabled={BigInt(user.egld_amount) < BigInt(min_hold)}
      />{' '}
      {BigInt(user.egld_amount) < BigInt(min_hold) && (
        <>
          Hold{' '}
          <>
            {formatAmount({
              input: (BigInt(min_hold) * BigInt(100000)).toString(),
              decimals: 18,
              digits: 2,
              showLastNonZeroDecimal: false,
              addCommas: true
            })}
            <img className='inline-incon' src={gas_logo} alt='gas-logo' />
          </>
        </>
      )}
    </div>
  );
};

export default GetUserPostVoteCacat;
