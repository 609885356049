import React from 'react';
import { useContext } from 'react';
import { UserContext } from 'components/hooks/useUser';
import axios from 'axios';
import { defaultToken, network } from 'config';
import withdraw from 'img/withdraw.png';
import { minGas } from 'config';
import TapGame from 'components/TapGame';

const GetUserPostWithdraw = () => {
  const context = useContext(UserContext);
  if (!context) {
    throw new Error('UserContextProvider');
  }
  const { user, setUser, refresh, setRefresh } = context;

  // const upgrade_price =
  //   user.pet_lvl > 0
  //     ? (BigInt(10) + BigInt(2) * BigInt(BigInt(user.pet_lvl) - BigInt(1))) *
  //       BigInt(10 ** 18)
  //     : BigInt(0);

  const postUserPetUpgrade = async () => {
    if (window.Telegram.WebApp.initData) {
      const initData = window.Telegram.WebApp.initData;
      try {
        const response = await axios.post(
          network.cacatApi + '/user/staking/withdraw',
          {
            initData,
            upgrade_token: defaultToken
          }
        );

        const data = response.data;
        if (data.message) {
          alert(data.message);
        }
        if (data.success) {
          // setUser((prevUser) => ({
          //   ...prevUser,
          //   cacat_amount: BigInt(user.cacat_amount) - BigInt(upgrade_price),
          //   pet_lvl: BigInt(user.pet_lvl) + BigInt(1)
          // }));
          setRefresh((r) => ({
            ...r,
            user_egld: true,
            user_cacat: true,
            user_vm: true,
            refreshing: true,
            txHash: data.txHash,
            savings: true,
            rewards: true
          }));
        } else {
          alert('Withdraw failed');
          await console.log(`User withdraw failed: ${JSON.stringify(data)}`);
        }
      } catch (error) {
        alert('Withdraw failed');
        await console.log(`Error during withdraw: ${error}`);
      }
    }
  };

  return (
    <div className='item'>
      Withdraw
      <TapGame
        imageReady={withdraw}
        imageExec={withdraw}
        imageLow={withdraw}
        gasCost={minGas.petUpgrade}
        userGas={user.egld_amount}
        userCacat={user.cacat_amount}
        tokenCost={0}
        requiredClicks={10}
        onAction={postUserPetUpgrade}
        disabled={user.savings.isLessThan(10 * 10 ** 18)}
      />
    </div>
  );
};

export default GetUserPostWithdraw;
