import React, { useContext } from 'react';
import GetClaimGasButton from 'components/helpers/postUserClaimGas';
import ShortenedAddress from 'components/ShortenedAddress';
import { UserContext } from 'components/hooks/useUser';
import GetBuyGasButton from 'components/helpers/postUserBuyGas';
import { Link } from 'react-router-dom';
import LobbyMenu from 'components/LobbyMenu';
import Game from './Kitchen/Kitchen';

const LobyPage = () => {
  const context = useContext(UserContext);
  if (!context) {
    throw new Error('UserContextProvider');
  }
  const { user, setUser, refresh, setRefresh } = context;

  return (
    <div>
      <Game />
      <LobbyMenu />
    </div>
  );
};

export default LobyPage;
