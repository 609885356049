import React, { useContext } from 'react';
import GetClaimGasButton from 'components/helpers/postUserClaimGas';
import ShortenedAddress from 'components/ShortenedAddress';
import { UserContext } from 'components/hooks/useUser';
import GetBuyGasButton from 'components/helpers/postUserBuyGas';
import { Link } from 'react-router-dom';
import LobbyMenu from 'components/LobbyMenu';
import { useGetIsLoggedIn } from 'hooks';

const EnergyPage = () => {
  const context = useContext(UserContext);
  if (!context) {
    throw new Error('UserContextProvider');
  }
  const { user, setUser, refresh, setRefresh } = context;
  const isLoggedIn = useGetIsLoggedIn();

  return (
    <div>
      <div>
        address: <ShortenedAddress address={user?.address} />
        <br />
        shard: {user?.shard}
      </div>

      <div className='grid-container'>
        <GetClaimGasButton />
        {BigInt(user.egld_amount) > BigInt(10 * 10 ** 16) && (
          <GetBuyGasButton />
        )}
      </div>

      <div>
        {!isLoggedIn ? (
          <div>
            <Link to='/unlock' className='menu-button'>
              Connect your wallet
            </Link>
          </div>
        ) : (
          <div className='grid-container'>
            <div className='item'>
              <Link to='/deposit' className='menu-button'>
                Transfert gas
              </Link>
            </div>
            <div className='item'>
              <Link to='/withdraw' className='menu-button'>
                Transfert cacat
              </Link>
            </div>
          </div>
        )}
      </div>
      <LobbyMenu />
    </div>
  );
};

export default EnergyPage;
