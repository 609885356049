import { useEffect, useContext, useState } from 'react';
import {
  Address,
  AddressValue,
  ContractFunction,
  ResultsParser,
  TokenIdentifierValue
} from '@multiversx/sdk-core/out';
import { ProxyNetworkProvider } from '@multiversx/sdk-network-providers';
import { defaultToken, network } from 'config';
import { stakingSC } from './../smartContracts';
import { UserContext } from 'components/hooks/useUser';
import BigNumber from 'bignumber.js';

const resultsParser = new ResultsParser();

export const useGetUserRewards = (address: any) => {
  const context = useContext(UserContext);
  if (!context) {
    throw new Error('UserContextProvider');
  }
  const { user, setUser, refresh, setRefresh } = context;

  //   getStakingPosition [ user_address, staked_token, rewarded_token]

  const getUserRewards = async () => {
    try {
      const query = stakingSC.createQuery({
        func: new ContractFunction('calculateRewardsForUser'),
        args: [
          new AddressValue(new Address(address)),
          new TokenIdentifierValue(defaultToken),
          new TokenIdentifierValue(defaultToken)
        ]
      });

      const proxy = new ProxyNetworkProvider(network.gatewayAddress);

      const queryResponse = await proxy.queryContract(query);

      const endpointDefinition = stakingSC.getEndpoint(
        'calculateRewardsForUser'
      );
      const { firstValue: position } = resultsParser.parseQueryResponse(
        queryResponse,
        endpointDefinition
      );
      const tab = position?.valueOf();
      if (tab && BigInt(tab) > BigInt(0)) {
        setUser((prevUser) => ({
          ...prevUser,
          rewards: new BigNumber(tab)
        }));
      } else {
        //si tab undef c'est que le user na plus de position
        setUser((prevUser) => ({
          ...prevUser,
          rewards: new BigNumber(0)
        }));
      }
      setRefresh((r) => ({
        ...r,
        rewards: false
      }));
    } catch (err) {
      alert(err);
      console.error('Unable to call getUserInfo', err);
    }
  };

  useEffect(() => {
    if (!address) {
      return;
    }
    let interval: any;
    if (refresh.rewards) {
      const executeFetch = async () => {
        if (refresh.txHash) {
        } else {
          if (refresh.rewards) {
            getUserRewards();
          }
        }
      };
      if (refresh.refreshing) {
        interval = setInterval(executeFetch, 1000);
      } else {
        executeFetch();
      }
    }
    return () => clearInterval(interval);
  }, [refresh, address]);
};
