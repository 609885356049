import React from 'react';
import { useContext, useState } from 'react';
import { UserContext } from 'components/hooks/useUser';
import axios from 'axios';
import { c_actions, defaultToken, network, sendFeedback } from 'config';
import petUpgradeReady from 'img/petUpgradeReady.png';
import { formatAmount } from '@multiversx/sdk-dapp/utils/operations';
import petUpgradeCost from 'img/petUpgradeCost.png';
import { minGas } from 'config';
import logo from 'img/logo.png';
import gas from 'img/gas.png';
import TapGame from 'components/TapGame';
import { CountdownTimer } from 'components/CountdownTimer';
import RewardCalcul2 from './RewardCalcul';
import BigNumber from 'bignumber.js';

const GetUserPlayUpgradeButton = () => {
  const action_id = 2;
  const action_type = 'upgrade';
  const context = useContext(UserContext);
  if (!context) {
    throw new Error('UserContextProvider');
  }
  const { user, setUser, refresh, setRefresh } = context;

  const upgrade_price = BigInt(
    c_actions[action_id].upgrade.base_cost +
      c_actions[action_id].upgrade.lvl_cost * BigInt(user.play_lvl)
  );

  // user.pet_lvl > 0
  //   ? (BigInt(10) + BigInt(2) * BigInt(BigInt(user.play_lvl))) *
  //     BigInt(10 ** 18)
  //   : BigInt(20);

  const postUserPlayUpgrade = async () => {
    if (window.Telegram.WebApp.initData) {
      const initData = window.Telegram.WebApp.initData;
      try {
        const response = await axios.post(network.cacatApi + '/user/upgrade', {
          initData,
          upgrade_price: upgrade_price.toString(),
          upgrade_token: defaultToken,
          action_id: action_id
        });

        const data = response.data;
        if (data.message) {
          alert(data.message);
        }
        if (data.success) {
          setUser((prevUser) => ({
            ...prevUser,
            cacat_amount: new BigNumber(
              BigInt(
                BigInt(user.cacat_amount.toFixed()) - BigInt(upgrade_price)
              ).toString()
            ),
            play_lvl_lvl: BigInt(user.play_lvl) + BigInt(1)
          }));
          setRefresh((r) => ({
            ...r,
            user_egld: true,
            user_cacat: true,
            user_vm: true,
            refreshing: true,
            txHash: data.txHash
          }));
        } else {
          alert('Pet upgrade failed');
          await console.log(`User pet upgrade failed: ${JSON.stringify(data)}`);
        }
      } catch (error) {
        alert('Pet upgrade failed');
        await console.log(`Error during pet upgrade: ${error}`);
      }
    }
  };
  const matchingAction: any = user.actions.filter(
    (action: any) =>
      action.action_id === action_id && action.type === action_type
  );

  return (
    <>
      <div className='item'>
        Play_lvl: {user?.play_lvl.toString()}{' '}
        <TapGame
          imageReady={petUpgradeReady}
          imageExec={petUpgradeReady}
          imageLow={petUpgradeCost}
          gasCost={minGas.petUpgrade}
          userGas={user.egld_amount}
          userCacat={user.cacat_amount}
          tokenCost={upgrade_price}
          requiredClicks={10}
          onAction={postUserPlayUpgrade}
          matchingAction={matchingAction}
          disabled={BigInt(user.pet_lvl) <= BigInt(user.play_lvl)}
        />{' '}
        <p>Upgrade Play</p>
        <RewardCalcul2
          reward={c_actions[action_id].reward}
          action_lvl={BigInt(user.play_lvl) + BigInt(1)}
        />
      </div>
    </>
  );
};

export default GetUserPlayUpgradeButton;
