import React from 'react';
import { useContext, useState } from 'react';
import { UserContext } from 'components/hooks/useUser';
import axios from 'axios';
import { defaultToken, network, sendFeedback } from 'config';
import claim from 'img/claim.png';
import { formatAmount } from '@multiversx/sdk-dapp/utils/operations';
import petUpgradeCost from 'img/petUpgradeCost.png';
import { minGas } from 'config';
import logo from 'img/logo.png';
import gas from 'img/gas.png';
import TapGame from 'components/TapGame';
import cacat_logo from 'img/cacat_logo.png';

const GetUserPostClaim = () => {
  const context = useContext(UserContext);
  if (!context) {
    throw new Error('UserContextProvider');
  }
  const { user, setUser, refresh, setRefresh } = context;

  const upgrade_price =
    user.pet_lvl > 0
      ? (BigInt(10) + BigInt(2) * BigInt(BigInt(user.pet_lvl) - BigInt(1))) *
        BigInt(10 ** 18)
      : BigInt(0);

  const postUserPetUpgrade = async () => {
    if (window.Telegram.WebApp.initData) {
      const initData = window.Telegram.WebApp.initData;
      try {
        const response = await axios.post(
          network.cacatApi + '/user/staking/claim',
          {
            initData
          }
        );

        const data = response.data;
        if (data.message) {
          alert(data.message);
        }
        if (data.success) {
          // setUser((prevUser) => ({
          //   ...prevUser,
          //   cacat_amount: BigInt(user.cacat_amount) - BigInt(upgrade_price),
          //   pet_lvl: BigInt(user.pet_lvl) + BigInt(1)
          // }));
          setRefresh((r) => ({
            ...r,
            user_egld: true,
            user_cacat: true,
            user_vm: true,
            refreshing: true,
            txHash: data.txHash
          }));
        } else {
          alert('Claim interest failed');
          await console.log(
            `User claim interest failed: ${JSON.stringify(data)}`
          );
        }
      } catch (error) {
        alert('Claim interest failed');
        await console.log(`Error during claim interest: ${error}`);
      }
    }
  };

  return (
    <div className='item'>
      Claim:{' '}
      <span className='text-with-icon'>
        {user?.rewards.isGreaterThan(0)
          ? formatAmount({
              input: user?.rewards.toFixed(),
              decimals: 18,
              digits: 2,
              showLastNonZeroDecimal: false
            })
          : '0'}{' '}
        <img className='inline-incon' src={cacat_logo} alt='cacat-logo' />
      </span>
      <TapGame
        imageReady={claim}
        imageExec={claim}
        imageLow={claim}
        gasCost={minGas.petUpgrade}
        userGas={user.egld_amount}
        userCacat={user.cacat_amount}
        tokenCost={0}
        requiredClicks={10}
        onAction={postUserPetUpgrade}
        disabled={user.rewards.isLessThan(10 * 10 ** 18)}
      />
    </div>
  );
};

export default GetUserPostClaim;
