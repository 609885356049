import { formatAmount } from '@multiversx/sdk-dapp/utils/operations';
import React from 'react';
import cacat_logo from 'img/cacat_logo.png';
import gas_logo from 'img/gas_logo.png';
import litter_logo from 'img/litter_logo.png';
import { c_actions } from 'config';
// const RewardCalcul = ({ action_id, action_lvl, litter_lvl }: any) => {
//   const result = calculateReward(action_id, action_lvl, litter_lvl);

//   // return <div>{result.toString()}</div>;
//   return (
//     <>
//       {BigInt(result) > BigInt(0) && (
//         <>
//           +
//           {formatAmount({
//             input: result?.toString(),
//             decimals: 18,
//             digits: 2,
//             showLastNonZeroDecimal: false
//           })}
//           <img className='inline-incon' src={litter_logo} alt='litter_logo' />
//         </>
//       )}
//     </>
//   );
// };

// export default RewardCalcul;

const RewardCalcul2 = ({ reward, action_lvl }: any) => {
  const result = calculateReward2(reward, action_lvl);

  // return <div>{result.toString()}</div>;
  return (
    <>
      {BigInt(result) > BigInt(0) && (
        <>
          +
          {formatAmount({
            input: result?.toString(),
            decimals: 18,
            digits: 2,
            showLastNonZeroDecimal: false
          })}
          <img className='inline-incon' src={litter_logo} alt='litter_logo' />
        </>
      )}
    </>
  );
};

export default RewardCalcul2;
export const calculateReward = (
  action_id: any,
  action_lvl: any,
  litter_lvl: any
) => {
  const isValidBigInt = (value: any) => {
    try {
      BigInt(value);
      return true;
    } catch (e) {
      return false;
    }
  };

  if (
    !isValidBigInt(action_id) ||
    !isValidBigInt(action_lvl) ||
    !isValidBigInt(litter_lvl)
  ) {
    return BigInt(0);
  }
  let litterBonus = BigInt(0);
  const baseValue = BigInt(10 ** 18) * BigInt(action_id);
  const taux =
    BigInt(action_lvl) > BigInt(0) ? BigInt(action_lvl) - BigInt(1) : BigInt(0);
  const increment = (baseValue * taux) / BigInt(10); // 10% increment per level
  if (BigInt(litter_lvl) > BigInt(0)) {
    litterBonus =
      BigInt(Number(litter_lvl) + Number(action_id) - 1) * BigInt(10 ** 17);
  }
  const result = baseValue + increment + litterBonus;

  return result;
};

export const calculateReward2 = (reward: any, action_lvl: any) => {
  const lvl = action_lvl > BigInt(0) ? BigInt(action_lvl) - BigInt(1) : 0;
  const isValidBigInt = (value: any) => {
    try {
      BigInt(value);
      return true;
    } catch (e) {
      return false;
    }
  };

  if (
    !isValidBigInt(reward.cost) ||
    !isValidBigInt(lvl) ||
    !isValidBigInt(reward.step) ||
    !isValidBigInt(reward.restep)
  ) {
    return BigInt(0);
  }

  //cout upgrade
  const B = BigInt(reward.cost) + BigInt(reward.step) * BigInt(lvl);
  // console.log('B: ', B.toString());
  //cout en click * 1 000 pour la gestion decimales
  const D = BigInt(reward.clics * 1000) + BigInt(reward.restep) * BigInt(lvl);
  // console.log('D: ', D.toString());
  //* 1000 pour gestion dec
  const r = (BigInt(B) * BigInt(1000)) / BigInt(D);
  // console.log('r: ', r.toString());

  return r;
};

export const calculateTotalReward = (litter: any, litter_lvl: any) => {
  // console.log('litter', litter);
  let totalReward = BigInt(0);
  if (!Array.isArray(litter)) {
    return totalReward;
  }
  for (const item of litter) {
    // const reward = calculateReward(item.action_id, item.action_lvl, 0);
    type ActionId = keyof typeof c_actions;
    const actionId: ActionId = item.action_id as ActionId;

    const reward = calculateReward2(
      c_actions[actionId].reward,
      item.action_lvl
    );
    if (reward !== null) {
      totalReward += BigInt(reward) * BigInt(item.count);
    }
  }

  return totalReward;
};
