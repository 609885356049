import React, { useContext } from 'react';
import axios from 'axios';
import { UserContext } from 'components/hooks/useUser';
import { c_actions, minGas, network } from 'config';
import cacat_logo from 'img/cacat_logo.png';
import gas_logo from 'img/gas_logo.png';
import energy_logo from 'img/energy_logo.png';
import reloading from 'img/reloading.png';
import notreloading from 'img/notreloading.png';
import { formatAmount } from '@multiversx/sdk-dapp/utils/operations/formatAmount';
import TapGame from 'components/TapGame';

const GetBuyGasButton = () => {
  const action_id = 1;
  const action_type = 'action';
  const context = useContext(UserContext);
  if (!context) {
    throw new Error('UserContextProvider');
  }
  const { user, setUser, refresh, setRefresh } = context;

  const postUserClaimGas = async () => {
    if (window.Telegram.WebApp.initData) {
      const initData = window.Telegram.WebApp.initData;
      try {
        const response = await axios.post(
          network.cacatApi + '/user/buyenergy',
          {
            initData
          }
        );

        const data = response.data;
        if (data.message) {
          alert(data.message);
        }
        if (data.success) {
          setUser((prevUser) => ({
            ...prevUser,
            gas: 0
          }));
          setRefresh((r) => ({
            ...r,
            user_egld: true,
            user_vm: true,
            refreshing: true,
            txHash: data.txHash
          }));
        } else {
          alert('Buy energy failed');
          await console.log(`User buy failed: ${JSON.stringify(data)}`);
        }
      } catch (error) {
        alert('Error during buy energy');
        await console.log(`Error during buy energy: ${error}`);
      }
    }
  };
  const min_hold = BigInt(10 ** 16);

  return (
    <div className='item'>
      + 2,000 <img className='inline-incon' src={energy_logo} alt='gas-logo' />
      <TapGame
        imageReady={user.gas < 80 ? notreloading : reloading}
        imageExec={reloading}
        imageLow={reloading}
        gasCost={BigInt(10 ** 16)}
        userGas={user.egld_amount}
        userCacat={user.cacat_amount}
        tokenCost={0}
        requiredClicks={20}
        onAction={postUserClaimGas}
        eneregyCost={c_actions[action_id][action_type].energy_cost}
        disabled={BigInt(user.egld_amount) < BigInt(min_hold)}
      />
    </div>
  );
};

export default GetBuyGasButton;
