import { useEffect, useState } from 'react';

export const CountdownTimer = (current_time: Date, matchingAction: any) => {
  //testing with user time instead than current_time
  const user_time = new Date();
  const [secondsRemaining, setSecondsRemaining] = useState(
    calculateDiffInSeconds(
      user_time,
      matchingAction?.length > 0
        ? new Date(matchingAction[0]?.ready_at)
        : new Date(new Date().getTime() - 24 * 60 * 60 * 1000)
    )
  );

  useEffect(() => {
    setSecondsRemaining(
      calculateDiffInSeconds(
        user_time,
        matchingAction?.length > 0
          ? new Date(matchingAction[0]?.ready_at)
          : new Date(new Date().getTime() - 24 * 60 * 60 * 1000)
      )
    );

    const intervalId = setInterval(() => {
      setSecondsRemaining((prevSecondsRemaining) => {
        if (prevSecondsRemaining > 0) {
          return prevSecondsRemaining - 1;
        } else {
          clearInterval(intervalId);
          return 0;
        }
      });
    }, 1000);
    return () => clearInterval(intervalId);
  }, [user_time, matchingAction]);
  return secondsRemaining;
};

const calculateDiffInSeconds = (current_time: Date, ready_time: Date) => {
  const diffInMilliseconds = ready_time
    ? ready_time.getTime() - current_time.getTime()
    : 0;
  return Math.floor(diffInMilliseconds / 1000);
};
