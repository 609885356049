import React, { useContext, useEffect, useState } from 'react';
import ShortenedAddress from 'components/ShortenedAddress';
import { UserContext } from 'components/hooks/useUser';
import { Link } from 'react-router-dom';
import GetWithdraw from 'components/helpers/postUserWithdraw';
import { useGetAccount, useGetIsLoggedIn } from 'hooks';
import './Transfer.css';
import { formatAmount } from '@multiversx/sdk-dapp/utils/operations';
import gas_logo from 'img/gas_logo.png';
import BigNumber from 'bignumber.js';
import GetDepositEgld from 'components/helpers/postUserDepositEgld';
import { defaultToken } from 'config';
import { useGetUserESDT } from '../Earn/components/helpers/useGetUserESDT';

const GasDepositPage = () => {
  const context = useContext(UserContext);
  if (!context) {
    throw new Error('UserContextProvider');
  }
  const { user } = context;
  const isLoggedIn = useGetIsLoggedIn();
  const { balance, address } = useGetAccount();

  const [isTelegramToXportal, setIsTelegramToXportal] = useState(false);
  const [telegramAmount, setTelegramAmount] = useState(new BigNumber(0));
  const [xportalAmount, setXportalAmount] = useState(new BigNumber(0));
  const [sendingAmount, setSendingAmount] = useState(
    new BigNumber('100000000000000000')
  );
  const [inputAmount, setInputAmount] = useState(0.1);

  useEffect(() => {
    setTelegramAmount(new BigNumber(0));
    setXportalAmount(new BigNumber(balance));
  }, [balance]);

  const convertToBigNumber = (amount: number) => {
    const cleanAmount = parseFloat(amount.toString().replace(',', '.'));
    return new BigNumber(Math.floor(cleanAmount * 10 ** 18));
  };
  // const fromBigIntWithDecimals = (amountBigInt: any) => {
  //   return parseFloat((BigInt(amountBigInt) / BigInt(10 ** 18)).toString()); // Convert to human-readable number
  // };

  // const swapValues = () => {
  //   setInputAmount(0);
  //   setIsTelegramToXportal(!isTelegramToXportal);
  // };

  const handleInputChange = (event: any) => {
    setInputAmount(event.target.value);
    // console.log(
    //   event.target.value,
    //   convertToBigNumber(event.target.value).toFixed()
    // );

    const value = event.target.value.replace(',', '.');

    if (isNaN(value) || value === '' || parseFloat(value) < 0) {
      return;
    }
    setSendingAmount(convertToBigNumber(event.target.value));
  };

  // const handleMaxClick = () => {
  //   if (isTelegramToXportal) {
  //     setInputAmount(fromBigIntWithDecimals(telegramAmount));
  //     setSendingAmount(telegramAmount);
  //   } else {
  //     setInputAmount(fromBigIntWithDecimals(xportalAmount));
  //     setSendingAmount(xportalAmount);
  //   }
  // };

  return (
    <div>
      <div>
        Cacat wallet: <ShortenedAddress address={user?.address} />
        xPortal wallet: <ShortenedAddress address={address} />
      </div>

      {!isLoggedIn ? (
        <div>
          <Link to='/unlock' className='menu-button'>
            Unlock
          </Link>
        </div>
      ) : (
        <div className='transfer-container'>
          <h1>EGLD to CACAT GAS</h1>

          <p className='transfer-info'>
            {isTelegramToXportal
              ? 'Transferring funds from Cacat Wallet to xPortal Wallet'
              : 'Transferring funds from xPortal Wallet to Cacat Wallet'}
          </p>

          <div className='wallets'>
            {/* Wallet 1 (modifiable) */}
            <div className='wallet'>
              <label htmlFor='amount-1'>
                {isTelegramToXportal
                  ? 'Cacat Wallet (Sending EGLD)'
                  : 'xPortal Wallet (Sending 💸)'}
              </label>
              <div className='input-max-container'>
                <input
                  style={{ margin: '0 auto' }}
                  type='number'
                  id='amount-1'
                  className={'modifiable input-field'}
                  value={inputAmount}
                  onChange={handleInputChange}
                  placeholder='Enter amount'
                  min='0.001'
                />
                {/* <button onClick={handleMaxClick} className='max-btn'>
                  MAX
                </button> */}
              </div>
            </div>

            {/* Swap Button avec flèche */}
            <button className='swap-btn'>
              <span className='arrow'>↓</span> 0.1 EGLD = 10,000.00{' '}
              <img className='inline-incon' src={gas_logo} alt='cacat-logo' />
            </button>

            {/* Wallet 2 (readonly) */}
            <div className='wallet'>
              <label htmlFor='amount-2'>
                {!isTelegramToXportal ? (
                  <span>Cacat Wallet (Receiving)</span>
                ) : (
                  'xPortal Wallet (Receiving 💰)'
                )}
              </label>
              <input
                type='text'
                id='amount-2'
                className='readonly input-field input-with-gas-icon'
                style={{ margin: '0 auto' }}
                value={
                  !isTelegramToXportal
                    ? formatAmount({
                        input: sendingAmount.toFixed(),
                        decimals: 13,
                        digits: 2,
                        addCommas: true,
                        showLastNonZeroDecimal: false
                      })
                    : formatAmount({
                        input: xportalAmount.toFixed(),
                        digits: 2,
                        showLastNonZeroDecimal: false
                      })
                }
                readOnly={true}
              />
            </div>
          </div>

          {/* Transfer Button */}
          <div className='transfer-btn-container'>
            {isTelegramToXportal ? (
              <></>
            ) : (
              <>
                <GetDepositEgld sendamount={sendingAmount} />
              </>
            )}
          </div>
        </div>
      )}
    </div>
  );
};

export default GasDepositPage;
