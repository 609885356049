import React, { useState } from 'react';
import { Link } from 'react-router-dom';
import { FiMenu } from 'react-icons/fi'; // Icône de menu burger
import './BurgerMenu.css'; // Vous pouvez styliser le menu ici

const BurgerMenu = () => {
  const [isOpen, setIsOpen] = useState(false);

  const toggleMenu = () => {
    setIsOpen(!isOpen);
  };

  return (
    <div className='burger-menu-container'>
      <div className='burger-icon' onClick={toggleMenu}>
        <FiMenu size={30} />
      </div>
      <div className={`menu-content ${isOpen ? 'open' : ''}`}>
        <Link to='/pet' onClick={toggleMenu}>
          Pet
        </Link>
        <Link to='/upgrade' onClick={toggleMenu}>
          Upgrade
        </Link>
        <Link to='/interest' onClick={toggleMenu}>
          Interest
        </Link>
        <Link to='/wallet' onClick={toggleMenu}>
          Wallet
        </Link>
        <Link to='/explore' onClick={toggleMenu}>
          Explore
        </Link>
        <Link to='/missions' onClick={toggleMenu}>
          Missions
        </Link>
      </div>
    </div>
  );
};

export default BurgerMenu;
