import React from 'react';
import { useContext, useState } from 'react';
import { UserContext } from 'components/hooks/useUser';
import axios from 'axios';
import { defaultToken, network, sendFeedback } from 'config';
import compound from 'img/compound.png';
import { formatAmount } from '@multiversx/sdk-dapp/utils/operations';
import petUpgradeCost from 'img/petUpgradeCost.png';
import { minGas } from 'config';
import logo from 'img/logo.png';
import gas from 'img/gas.png';
import TapGame from 'components/TapGame';

const GetUserPostCompound = () => {
  const context = useContext(UserContext);
  if (!context) {
    throw new Error('UserContextProvider');
  }
  const { user, setUser, refresh, setRefresh } = context;

  const postUserCompound = async () => {
    if (window.Telegram.WebApp.initData) {
      const initData = window.Telegram.WebApp.initData;
      try {
        const response = await axios.post(
          network.cacatApi + '/user/staking/compound',
          {
            initData,
            upgrade_token: defaultToken
          }
        );

        const data = response.data;
        if (data.message) {
          alert(data.message);
        }
        if (data.success) {
          // setUser((prevUser) => ({
          //   ...prevUser,
          //   cacat_amount: BigInt(user.cacat_amount) - BigInt(upgrade_price),
          //   pet_lvl: BigInt(user.pet_lvl) + BigInt(1)
          // }));
          setRefresh((r) => ({
            ...r,
            user_egld: true,
            user_vm: true,
            refreshing: true,
            txHash: data.txHash,
            savings: true,
            rewards: true
          }));
        } else {
          alert('Compound failed');
          await console.log(`User compound failed: ${JSON.stringify(data)}`);
        }
      } catch (error) {
        alert('Compound failed');
        await console.log(`Error during compound: ${error}`);
      }
    }
  };

  return (
    <div className='item'>
      Compound
      <TapGame
        imageReady={compound}
        imageExec={compound}
        imageLow={compound}
        gasCost={minGas.compound}
        userGas={user.egld_amount}
        userCacat={user.cacat_amount}
        tokenCost={0}
        requiredClicks={10}
        disabled={user.rewards.isLessThan(10 * 10 ** 18)}
        onAction={postUserCompound}
      />
    </div>
  );
};

export default GetUserPostCompound;
