import React from 'react';

const AboutPage = () => {
  return (
    <div>
      <ul></ul>
      <li>Energy is used to perform actions and recharges over time.</li>
      <li>
        Gas can be periodically reclaimed, but it is rare, and active users
        share a common resource. It is used to validate on-chain operations. 1
        EGLD = 100,000 gas.
      </li>
      <li>
        $CACAT is an on-chain token that can be transferred from one account to
        another. Gas is required to transfer CACAT.
      </li>
      <li>
        This game uses hosted wallets, which are not suitable for storing value,
        and we retain full ownership.
      </li>
    </div>
  );
};

export default AboutPage;
