import React from 'react';
import cacat_logo from 'img/cacat_logo.png';
import gas_logo from 'img/gas_logo.png';
const NotFoundPage = () => {
  return (
    <div className=''>
      <div className='appTitle'>We are building something.</div>
      <div className=''>Come back later</div>
    </div>
  );
};

export default NotFoundPage;
