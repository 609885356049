import React, { useState } from 'react';
import { useContext } from 'react';
import { UserContext } from 'components/hooks/useUser';
import { Link } from 'react-router-dom';

const AlertGasLow = () => {
  const context = useContext(UserContext);
  if (!context) {
    throw new Error('UserContextProvider');
  }
  const { user, setUser, refresh, setRefresh } = context;
  return (
    <>
      {user.litter_percent >= 100 &&
        BigInt(user.egld_amount) < BigInt(50 * 10 ** 13) && (
          <div className='alert'>
            <div className='alert-text'>
              Litter is full and gas is low.
              <br />
              Please{' '}
              <Link
                to='/wallet'
                className='inline'
                style={{ display: 'inline-block' }}
              >
                refill gas
              </Link>
            </div>{' '}
            .
          </div>
        )}
    </>
  );
};

export default AlertGasLow;
