import React, { useEffect } from 'react';
import { useContext, useState } from 'react';
import { UserContext } from 'components/hooks/useUser';
import axios from 'axios';
import { network } from 'config';

import { formatAmount } from '@multiversx/sdk-dapp/utils/operations';

import BigNumber from 'bignumber.js';
import { Address } from '@multiversx/sdk-core';
import gas_logo from 'img/gas_logo.png';
import { useGetAccount, useGetIsLoggedIn } from 'hooks';

const GetUserPostWithdraw = ({ sendamount }: any) => {
  const [sendingAmount, setSendingAmount] = useState(new BigNumber(sendamount));
  useEffect(() => {
    setSendingAmount(new BigNumber(sendamount));
  }, [sendamount]);

  const { address } = useGetAccount();
  const [form_address, setAddress] = useState(
    address ? address : 'Connect with xPortal'
  );
  const [errorMessage, setErrorMessage] = useState('');

  const context = useContext(UserContext);
  if (!context) {
    throw new Error('UserContextProvider');
  }
  const { user, setUser, refresh, setRefresh } = context;

  const isValidAddress = (address: string) => {
    try {
      const addr = new Address(address);
      return addr.bech32().startsWith('erd1');
    } catch (e) {
      return false;
    }
  };

  const handleSubmit = (e: any) => {
    e.preventDefault();

    if (!isValidAddress(form_address)) {
      setErrorMessage('Invalid MultiversX address.');
      return;
    }

    // if (Number(sendamount) < 100) {
    //   setErrorMessage('100 cacat min');
    //   return;
    // }

    // Traitement du formulaire si l'adresse est valide
    // console.log('Adresse de retrait:', form_address);
    // console.log('Montant du retrait:', form_amount);
    // setAddress('');
    setErrorMessage('');
    postUserWithdraw();
  };

  useEffect(() => {
    if (isValidAddress(address)) {
      setAddress(address);
    }
  }, [address, useGetIsLoggedIn()]);

  const postUserWithdraw = async () => {
    if (window.Telegram.WebApp.initData) {
      const initData = window.Telegram.WebApp.initData;
      try {
        const response = await axios.post(network.cacatApi + '/user/withdraw', {
          initData,
          amount: new BigNumber(sendingAmount).toFixed(),
          address: form_address
        });

        const data = response.data;
        if (data.message) {
          alert(data.message);
        }
        if (Array.isArray(data?.actions)) {
          setUser((prevUser) => ({
            ...prevUser,
            actions: data.actions
          }));
        }
        if (data.success) {
          setUser((prevUser) => ({
            ...prevUser,
            cacat_amount: new BigNumber(user.cacat_amount.minus(sendamount))
          }));
          setRefresh((r) => ({
            ...r,
            user_egld: true,
            user_cacat: true,
            refreshing: true,
            txHash: data.txHash
          }));
        } else {
          alert('Withdraw failed');
          await console.log(`Withdraw failed: ${JSON.stringify(data)}`);
        }
      } catch (error) {
        alert('Withdraw failed');
        await console.log(`Error during Withdraw: ${error}`);
      }
    }
  };

  return (
    <div>
      <form onSubmit={handleSubmit}>
        {errorMessage && <p style={{ color: 'red' }}>{errorMessage}</p>}

        <button type='submit' className='transfer-btn'>
          <>
            -
            {formatAmount({
              input: '13000000000000000000',
              decimals: 18,
              digits: 2,
              showLastNonZeroDecimal: false,
              addCommas: true
            })}
            <img className='inline-incon' src={gas_logo} alt='gas-logo' />
          </>{' '}
          Confirm transfer
        </button>
      </form>
      {refresh.txHash && (
        <>
          {refresh.txHash.slice(0, 6)}...{refresh.txHash.slice(-6)} ⌛️
        </>
      )}
    </div>
  );
};

export default GetUserPostWithdraw;
