import React, { useEffect } from 'react';
import { useContext, useState } from 'react';
import { UserContext } from 'components/hooks/useUser';
import axios from 'axios';
import { defaultToken, network } from 'config';
import { formatAmount } from '@multiversx/sdk-dapp/utils/operations';
import BigNumber from 'bignumber.js';
import { Address } from '@multiversx/sdk-core';
import gas_logo from 'img/gas_logo.png';
import { useGetAccount, useGetIsLoggedIn } from 'hooks';
import { sendTransactions } from '@multiversx/sdk-dapp/services';
import { refreshAccount } from '@multiversx/sdk-dapp/utils';
import bigToHex from './helpers/bigToHex';

const GetDeposit = ({ sendamount }: any) => {
  const [sendingAmount, setSendingAmount] = useState(new BigNumber(sendamount));
  useEffect(() => {
    setSendingAmount(new BigNumber(sendamount));
  }, [sendamount]);

  // const { address } = useGetAccount();

  const context = useContext(UserContext);
  if (!context) {
    throw new Error('UserContextProvider');
  }
  const { user, setUser, refresh, setRefresh } = context;

  const /*transactionSessionId*/ [, setTransactionSessionId] = useState<
      string | null
    >(null);

  const sendDepositTransaction = async () => {
    const depositTransaction = {
      value: 0,
      data:
        'ESDTTransfer@' +
        Buffer.from(defaultToken, 'utf8').toString('hex') +
        '@' +
        bigToHex(BigInt(sendingAmount.toFixed())),
      receiver: user.address,
      gasLimit: '5000000'
    };
    await refreshAccount();

    const { sessionId /*, error*/ } = await sendTransactions({
      transactions: depositTransaction,
      transactionsDisplayInfo: {
        processingMessage: 'Processing deposit transaction',
        errorMessage: 'An error has occured depositing',
        successMessage: 'Deposit transaction successful'
      },
      redirectAfterSign: false
    });
    if (sessionId != null) {
      setTransactionSessionId(sessionId);
    }
  };

  return (
    <div>
      <div>
        <button
          onClick={sendDepositTransaction}
          type='submit'
          className='transfer-btn'
        >
          Confirm transfer
        </button>
      </div>
      {refresh.txHash && (
        <>
          {refresh.txHash.slice(0, 6)}...{refresh.txHash.slice(-6)} ⌛️
        </>
      )}
    </div>
  );
};

export default GetDeposit;
