import React, { useContext } from 'react';
import { Link } from 'react-router-dom';
import './LobbyMenu.css';
import { UserContext } from './hooks/useUser';
import { CountdownTimer } from './CountdownTimer';

const LobbyMenu = ({ back = false }: any) => {
  const context = useContext(UserContext);
  if (!context) {
    throw new Error('App must be used within a UserContextProvider');
  }
  const { user, setUser, refresh, setRefresh } = context;

  const PlayAction: any = user?.actions?.filter(
    (action: any) => action.action_id === 2 && action.type === 'action'
  );
  const PlayTimer = CountdownTimer(user.isoDate, PlayAction);
  const FeedAction: any = user?.actions?.filter(
    (action: any) => action.action_id === 3 && action.type === 'action'
  );
  const FeedTimer = CountdownTimer(user.isoDate, FeedAction);

  return (
    <div className='lobby-menu'>
      {back ? (
        <Link to={'/'} className='lobby-button back'></Link>
      ) : (
        <>
          <Link
            to={'/actionpet'}
            className={`lobby-button ${user.litter_percent >= 100 ? 'red' : ''}`}
          >
            <span>PET</span>
          </Link>
          <Link
            to={'/actionplay'}
            className={`lobby-button ${
              user.play_lvl == BigInt(0)
                ? 'disabled'
                : PlayTimer > 0 || user.litter_percent >= 100
                  ? 'red'
                  : PlayAction.length > 0
                    ? 'green'
                    : ''
            }`}
            onClick={(e) => {
              if (user.play_lvl == BigInt(0)) e.preventDefault();
            }}
          >
            <span>PLAY</span>
          </Link>
          <Link
            to={'/actionfeed'}
            className={`lobby-button ${
              user.feed_lvl == BigInt(0)
                ? 'disabled'
                : FeedTimer > 0 || user.litter_percent >= 100
                  ? 'red'
                  : FeedAction.length > 0
                    ? 'green'
                    : ''
            }`}
            onClick={(e) => {
              if (user.feed_lvl == BigInt(0)) e.preventDefault();
            }}
          >
            <span>FEED</span>
          </Link>
          <Link
            to={'/actionlitter'}
            className={`lobby-button ${
              user.litter_percent < 50
                ? 'red'
                : user.litter_percent < 100
                  ? 'orange'
                  : 'green'
            }`}
          >
            <div
              id='target-litter'
              style={{
                position: 'absolute',
                width: '1px',
                height: '1px',
                zIndex: 1001
              }}
            ></div>
            <span>LITTER </span>
          </Link>
        </>
      )}
    </div>
  );
};

export default LobbyMenu;
