import React from 'react';
import { useContext } from 'react';
import { UserContext } from 'components/hooks/useUser';
import axios from 'axios';
import { c_actions, defaultToken, network } from 'config';
import { minGas } from 'config';

import TapGame from 'components/TapGame';
import feedUpgradeReady from 'img/petUpgradeReady.png';
import feedUpgradeCost from 'img/petUpgradeCost.png';
import RewardCalcul2 from './RewardCalcul';
import BigNumber from 'bignumber.js';

const GetUserFeedUpgradeButton = () => {
  const action_id = 3;
  const action_type = 'upgrade';
  const context = useContext(UserContext);
  if (!context) {
    throw new Error('UserContextProvider');
  }
  const { user, setUser, refresh, setRefresh } = context;

  // const upgrade_price =
  //   user.feed_lvl > 0
  //     ? (BigInt(20) + BigInt(2) * BigInt(BigInt(user.feed_lvl))) *
  //       BigInt(10 ** 18)
  //     : BigInt(10 * 10 ** 18);
  const upgrade_price = BigInt(
    c_actions[action_id].upgrade.base_cost +
      c_actions[action_id].upgrade.lvl_cost * BigInt(user.feed_lvl)
  );
  const postUserFeedUpgrade = async () => {
    if (window.Telegram.WebApp.initData) {
      const initData = window.Telegram.WebApp.initData;
      try {
        const response = await axios.post(network.cacatApi + '/user/upgrade', {
          initData,
          upgrade_price: upgrade_price.toString(),
          upgrade_token: defaultToken,
          action_id: action_id
        });

        const data = response.data;
        if (data.message) {
          alert(data.message);
        }
        if (data.success) {
          setUser((prevUser) => ({
            ...prevUser,
            cacat_amount: new BigNumber(
              BigInt(
                BigInt(user.cacat_amount.toFixed()) - BigInt(upgrade_price)
              ).toString()
            ),

            feed_lvl: BigInt(user.feed_lvl) + BigInt(1)
          }));
          setRefresh((r) => ({
            ...r,
            user_egld: true,
            user_cacat: true,
            user_vm: true,
            refreshing: true,
            txHash: data.txHash
          }));
        } else {
          alert(`Feed upgrade failed ${JSON.stringify(data)}`);
          await console.log(
            `User feed upgrade failed: ${JSON.stringify(data)}`
          );
        }
      } catch (error) {
        alert(`Feed upgrade failed ${JSON.stringify(error)}`);
        await console.log(`Error during feed upgrade: ${error}`);
      }
    }
  };
  const matchingAction: any = user.actions.filter(
    (action: any) =>
      action.action_id === action_id && action.type === action_type
  );

  return (
    <>
      <div className='item'>
        Feed_lvl: {user?.feed_lvl.toString()}{' '}
        <TapGame
          imageReady={feedUpgradeReady}
          imageExec={feedUpgradeReady}
          imageLow={feedUpgradeCost}
          gasCost={minGas.petUpgrade}
          userGas={user.egld_amount}
          userCacat={user.cacat_amount}
          tokenCost={upgrade_price}
          requiredClicks={10}
          onAction={postUserFeedUpgrade}
          disabled={BigInt(user.play_lvl) <= BigInt(user.feed_lvl)}
          matchingAction={matchingAction}
        />
        <p>Upgrade Feed</p>
        <RewardCalcul2
          reward={c_actions[action_id].reward}
          action_lvl={BigInt(user.feed_lvl) + BigInt(1)}
        />
      </div>
    </>
  );
};
export default GetUserFeedUpgradeButton;
