import React from 'react';
import { useContext } from 'react';
import { UserContext } from 'components/hooks/useUser';
import axios from 'axios';
import { defaultToken, network } from 'config';
import deposit from 'img/deposit.png';
import { minGas } from 'config';
import TapGame from 'components/TapGame';
import gas_logo from 'img/gas_logo.png';
import { formatAmount } from '@multiversx/sdk-dapp/utils/operations';
import BigNumber from 'bignumber.js';

const GetUserPostDeposit = () => {
  const context = useContext(UserContext);
  if (!context) {
    throw new Error('UserContextProvider');
  }
  const { user, setUser, refresh, setRefresh } = context;

  const postUserDeposit = async () => {
    if (window.Telegram.WebApp.initData) {
      const initData = window.Telegram.WebApp.initData;
      try {
        const response = await axios.post(
          network.cacatApi + '/user/staking/deposit',
          {
            initData,
            upgrade_price: user.cacat_amount.toString(),
            upgrade_token: defaultToken
          }
        );

        const data = response.data;
        if (data.message) {
          alert(data.message);
        }
        if (data.success) {
          setUser((prevUser) => ({
            ...prevUser,
            cacat_amount: new BigNumber(0)
          }));
          setRefresh((r) => ({
            ...r,
            user_egld: true,
            user_cacat: true,
            interest_vm: true,
            refreshing: true,
            txHash: data.txHash,
            savings: true,
            rewards: true
          }));
        } else {
          alert('Deposit failed');
          await console.log(`User deposit failed: ${JSON.stringify(data)}`);
        }
      } catch (error) {
        alert('Deposit failed');
        await console.log(`Error during deposit: ${error}`);
      }
    }
  };
  //min hold is 1 EGLD (100 000 gas) 18
  //min hold is 0.1 EGLD (10 000 gas) 17
  //min hold is 0.01 EGLD (1 000 gas) 16
  const min_hold = BigInt(10 ** 16);

  return (
    <div className='item'>
      Deposit & Compound
      <TapGame
        imageReady={deposit}
        imageExec={deposit}
        imageLow={deposit}
        gasCost={minGas.deposit}
        userGas={user.egld_amount}
        userCacat={user.cacat_amount}
        tokenCost={user.cacat_amount}
        requiredClicks={10}
        onAction={postUserDeposit}
        disabled={
          user.cacat_amount.isLessThan(10 * 10 ** 18) ||
          BigInt(user.egld_amount) < BigInt(min_hold)
        }
      />
      {BigInt(user.egld_amount) < BigInt(min_hold) && (
        <>
          Hold{' '}
          <>
            {formatAmount({
              input: (BigInt(min_hold) * BigInt(100000)).toString(),
              decimals: 18,
              digits: 2,
              showLastNonZeroDecimal: false,
              addCommas: true
            })}
            <img className='inline-incon' src={gas_logo} alt='gas-logo' />
          </>
        </>
      )}
    </div>
  );
};

export default GetUserPostDeposit;
