import axios from 'axios';
import { useContext, useEffect, useState } from 'react';
import { ResultsParser } from '@multiversx/sdk-core/out';
import { defaultToken, network } from 'config';
import { shards } from 'config';
import { UserContext } from 'components/hooks/useUser';
import BigNumber from 'bignumber.js';

//Récupère la balance du sc de la shard pour savoir si
//le robber peut passer à l'action

const useGetScBalance = (shard: any) => {
  const context = useContext(UserContext);
  if (!context) {
    throw new Error('UserContextProvider');
  }
  const { user, setUser, refresh, setRefresh } = context;
  //Get cacat balance

  const fetchAccountCacact = async () => {
    try {
      const balance = await axios.get(
        network.apiAddress +
          '/accounts/' +
          shards[shard] +
          '/tokens/' +
          defaultToken
      );

      if (balance?.data?.balance) {
        setUser((u) => ({
          ...u,
          bank_balance: balance?.data?.balance
            ? new BigNumber(balance?.data?.balance)
            : new BigNumber(0)
        }));
      }
    } catch (err: any) {
      //wallet with no cacat return a 404
      // https://devnet-api.multiversx.com/accounts/erd1s2tstpvulqzhppydk876ydf6zce8svfznpe460plqnj0je5qx83qew5k2l/tokens/CACAT-672714
      // {"statusCode":404,"message":"Token for given account not found"}
      //   alert('no cacat in bank');
      console.error('Unable to call usercacat', err);
    }
  };

  useEffect(() => {
    if (shard === null) {
      return;
    }
    fetchAccountCacact();
  }, [shard]);
};

export default useGetScBalance;
